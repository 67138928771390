import React, { createContext } from "react";

import useWhatsApps from "../../hooks/useWhatsApps";

const WhatsAppsContext = createContext();

const WhatsAppsProvider = ({ children }) => {
	const {
		loading,
		whatsApps,
		permiteResposta,
		setPermiteResposta,
		permiteAssinar,
		setPermiteAssinar
	} = useWhatsApps();

	return (
		<WhatsAppsContext.Provider value={{
			whatsApps,
			loading,
			permiteResposta,
			setPermiteResposta,
			permiteAssinar,
			setPermiteAssinar }}>
			{children}
		</WhatsAppsContext.Provider>
	);
};

export { WhatsAppsContext, WhatsAppsProvider };
